import { render, staticRenderFns } from "./Label.vue?vue&type=template&id=d1c9f0e0&scoped=true"
import script from "./Label.vue?vue&type=script&lang=js"
export * from "./Label.vue?vue&type=script&lang=js"
import style0 from "./Label.vue?vue&type=style&index=0&id=d1c9f0e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1c9f0e0",
  null
  
)

export default component.exports