<template>
  <div class="writeArticle">
    <!-- 写文章 -->
    <div class="writeTop">
      <div class="left">
        <el-input placeholder="请输入文章标题" v-model="article.title" clearable size="small"></el-input>
        <el-input placeholder="请输入文章副标题" v-model="article.subTitle" clearable size="small">
        </el-input>
      </div>
      <div class="right">
        <el-button type="primary" size="small" v-if="isEdit" @click="updateStatus('draft')">保存草稿</el-button>
        <el-button type="primary" size="small" v-if="isEdit" @click="updateStatus('trash')">移至垃圾箱</el-button>
        <el-button type="primary" size="small" @click="submitArticle('normal')">发布</el-button>
      </div>
    </div>
    <div class="writeContent">
      <div class="left">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor style="height: 500px;max-width: 1160px;  overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
          @onCreated="onCreated" />
      </div>
      <div class="right">
        <el-collapse accordion>
          <el-collapse-item title="基础">
            <div class="item">
              <p>文章摘要</p>
              <el-input placeholder="请输入" v-model="article.summary" clearable size="small"></el-input>

            </div>
            <div class="item">
              <p>排序序号</p>
              <el-input placeholder="请输入" v-model="article.orderNumber" clearable type="number" size="small"></el-input>
              <span>文章列表会根据这个数值进行排序，越大越靠前。</span>
            </div>
            <div class="item">
              <p>外链</p>
              <el-input placeholder="请输入" v-model="article.linkTo" clearable size="small"></el-input>
              <span>填写外链后，浏览文章将会跳转到此链接</span>
            </div>
            <div class="item">
              <p>发布时间</p>
              <el-date-picker v-model="article.modified" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                placeholder="选择日期时间" size="small">
              </el-date-picker>
            </div>
          </el-collapse-item>
          <el-collapse-item title="固定链接">
            <div class="item">
              <!-- slug -->
              <span>网址:{{ settingsInfo.siteAddress }}/detail/{{ article.slug }}
                <!-- <span class="editSpan">点击编辑</span> -->
                <el-popover v-model="visible" placement="top" width="160">
                  <el-input v-model="siteInfo" size="small" placeholder="请输入内容"></el-input>
                  <!-- <p>这是一段内容这是一段内容确定删除吗？</p> -->
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="sureSite">确定</el-button>
                  </div>
                  <el-button slot="reference" type="text">点击编辑</el-button>
                </el-popover>
              </span>
            </div>
          </el-collapse-item>
          <el-collapse-item title="分类">
            <div class="item">
              <el-tree :data="typeList" node-key="id" default-expand-all :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <el-checkbox v-model="data.select">{{ data.title }}</el-checkbox>
                  <!-- <span></span> -->
                </span>
              </el-tree>
            </div>
          </el-collapse-item>
          <el-collapse-item title="图片">
            <div class="item">
              <el-upload class="avatar-uploader"
                :action="$store.state.requestAPI + `/attachment/upload?ct=${APIinfo.ct}&sign=${APIinfo.sign}&jpressAppId=${APIinfo.jpressAppId}`"
                :show-file-list="false" :data="{
                  'id': 0
                }" :on-success="handleAvatarSuccess">
                <img v-if="article.thumbnail" :src="$store.state.requestAPI + article.thumbnail" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- <el-upload class="avatar-uploader" :action="$store.state.requestAPI + '/attachment/upload'" :data="{
                'id': 0, ...APIinfo
              }" :show-file-list="false" :on-success="handleAvatarSuccess" >
                <img v-if="article.thumbnail" :src="article.thumbnail" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
            </div>
          </el-collapse-item>
          <el-collapse-item title="标签">
            <div class="item">
              <el-select v-model="tagValueList" multiple filterable allow-create default-first-option
                placeholder="请选择文章标签">
                <el-option v-for="item in tagList" :key="item.id" :label="item.title" :value="item.title">
                </el-option>
              </el-select>
              <!-- <el-input placeholder="请输入" v-model="article.tag" clearable size="small"></el-input> -->
              <!-- <el-select v-model="value" multiple filterable allow-create default-first-option placeholder="请选择文章标签">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select> -->
              <span>多个标签请用 “回车键” 或英文逗号（,）隔开。</span>
            </div>
          </el-collapse-item>
          <el-collapse-item title="SEO">
            <div class="item">
              <p>SEO标题</p>
              <el-input placeholder="请输入" v-model="article.metaTitle" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>SEO关键字</p>
              <el-input placeholder="请输入" v-model="article.metaKeywords" clearable size="small"></el-input>
            </div>
            <div class="item">
              <p>SEO描述</p>
              <el-input placeholder="请输入" v-model="article.metaDescription" clearable size="small"></el-input>
            </div>
          </el-collapse-item>
          <el-collapse-item title="状态" class="statusCollapse">
            <!-- <div class="item">
              <span>是否评论</span> <el-switch v-model="article.commentStatus">
              </el-switch>
            </div> -->
            <div class="item">
              <span>是否推荐</span> <el-switch v-model="article.withRecommend">
              </el-switch>
            </div>
            <div class="item">
              <span>是否置顶</span> <el-switch v-model="article.withTop">
              </el-switch>
            </div>
            <div class="item">
              <span>是否热门</span> <el-switch v-model="article.withHot">
              </el-switch>
            </div>
            <div class="item">
              <span>是否头条</span> <el-switch v-model="article.withLeadNews">
              </el-switch>
            </div>
            <div class="item">
              <span>是否允许被搜索</span> <el-switch v-model="article.withAllowSearch">
              </el-switch>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { DomEditor } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { createArticleAPI, articleDetailAPI, updateArticleAPI } from '@/api/article/TCCArticle'
import { articleTypeListAPI } from '@/api/article/TCCType'
import { articleTagsListAPI } from '@/api/article/TCCTags'
import { basicSettingsAPI } from '@/api/settings/basic'
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      visible: false,
      defaultTypeList: [], // 之前选择的分类
      settingsInfo: {}, //基础设置的信息
      typeList: [], // 文章分类数组
      tagList: [], // 文章标签数组
      tagValueList: [], // 标签选中的数组
      selectList: [], // 选择的分类的id
      editor: null,
      siteInfo: null, // 修改固定链接
      html: '',
      text: '',
      toolbarConfig: {},
      APIinfo: {},
      editorConfig: {
        placeholder: '请输入内容...', MENU_CONF: {}
      },
      mode: 'default',// or 'simple'
      // 图片
      imageUrl: '',
      article: {
        text: '', // 纯文本内容
        userId: null, // 用户id
        title: null,  // 标题
        subTitle: null, // 文章副标题
        author: null, //作者
        commentCount: null, //评论总数
        commentStatus: true, //评论状态，默认允许评论
        commentTime: null, //最后评论时间
        content: null, //内容
        created: null, // 创建时间
        editMode: null, // 编辑模式，默认为html，其他可选项包括html，markdown 
        flag: null, // 标识，通常用于对某几篇文章进行标识，从而实现单独查询
        // id: null, // 主键ID
        linkTo: null, // 连接到(常用于谋文章只是一个连接)
        // list: [], // 文章集合
        metaDescription: null, //SEO描述信息
        metaKeywords: null, //SEO关键字
        metaTitle: null, // SEO标题
        modified: null, // 最后更新时间
        options: null, //json扩展
        orderNumber: null, // 排序编号
        pid: null, // 子版本的文章id
        siteId: null, // 站点ID
        slug: null, // slug  固定链接
        tag: null, // 标签
        status: 'normal', // 状态
        style: null, // 样式
        summary: null, // 摘要
        thumbnail: null, // 缩略图
        userId: null,  // 用户id
        viewCount: null, // 访问量
        withAllowSearch: false, // 是否允许被搜索
        withHot: false, // 是否热门
        withLeadNews: false, // 是否头条
        withRecommend: false, // 是否推荐
        withTop: false //  是否置顶
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isEdit: false, // 新建还是修改


    }
  },
  methods: {
    // ----------------富文本编辑器
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      // this.text = this.editor.getText();

      // console.log(this.editorConfig)
    },
    // ----------------富文本编辑器------------------------

    // --------------上传图片-------------
    handleAvatarSuccess(res, file) {
      console.log(res)
      this.article.thumbnail = res.data.path;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // ----------------------上传图片 --------------

    // 递归取出选中的分类
    getSelectList(val) {
      if (val) {
        val.forEach(item => {
          if (item.select === true) {
            this.selectList.push(item.id);
          }
          if (item.children) {
            this.getSelectList(item.children)
          }
        })
      }
    },
    // 确定确定链接
    sureSite() {
      this.article.slug = this.siteInfo;
      // console.log(this.article)
      // this.$forceUpdate();
      this.visible = false;
    },
    //获取签名信息
    uploadFile() {
      this.$store.dispatch('getAPI');
      this.APIinfo = this.$store.state.APIinfo;
    },
    // 提交
    async submitArticle() {
      // this.html = this.editor.getText();
      console.log(this.editor.getText())
      if (this.article.title === null) {
        this.$message({
          showClose: true,
          message: '文章标题不能为空！'
        });
      } else if (this.html === null) {
        this.$message({
          showClose: true,
          message: '文章内容不能为空！'
        });
      } else {

        // console.log(this.editor.getText())
        this.article.text = this.editor.getText();
        this.getSelectList(this.typeList)
        this.article.userId = (JSON.parse(sessionStorage.getItem('user'))).id
        // console.log(this.selectList)
        this.article.tag = this.tagValueList.join(',')
        this.article.categorysId = [...new Set(this.selectList)];
        this.article.content = this.html;
        // console.log(this.article)
        this.article.status = 'normal'
        let res = {};
        // 判断是新增还是修改
        if (this.isEdit === false) {
          res = await createArticleAPI(JSON.stringify(this.article));
          console.log(res);
        } else {
          res = await updateArticleAPI(JSON.stringify(this.article));
          console.log(res);
        }

        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '操作成功！',
            type: 'success'
          });
          setTimeout(() => {
            this.html = ''
            this.tagValueList = [];
            this.selectList = [];
            this.defaultTypeList = [];
            this.article.categorysId = [];
            this.article = {
              userId: null, // 用户id
              title: null,  // 标题
              subTitle: null, // 文章副标题
              author: null, //作者
              commentCount: null, //评论总数
              commentStatus: true, //评论状态，默认允许评论
              commentTime: null, //最后评论时间
              content: null, //内容
              created: null, // 创建时间
              editMode: null, // 编辑模式，默认为html，其他可选项包括html，markdown 
              flag: null, // 标识，通常用于对某几篇文章进行标识，从而实现单独查询
              // id: null, // 主键ID
              linkTo: null, // 连接到(常用于谋文章只是一个连接)
              // list: [], // 文章集合
              metaDescription: null, //SEO描述信息
              metaKeywords: null, //SEO关键字
              metaTitle: null, // SEO标题
              modified: null, // 最后更新时间
              options: null, //json扩展
              orderNumber: null, // 排序编号
              pid: null, // 子版本的文章id
              siteId: null, // 站点ID
              slug: null, // slug  固定链接
              tag: null, // 标签
              status: 'normal', // 状态
              style: null, // 样式
              summary: null, // 摘要
              thumbnail: null, // 缩略图
              userId: null,  // 用户id
              viewCount: null, // 访问量
              withAllowSearch: false, // 是否允许被搜索
              withHot: false, // 是否热门
              withLeadNews: false, // 是否头条
              withRecommend: false, // 是否推荐
              withTop: false //  是否置顶
            }
            this.getTypeList();
            this.isEdit = false;
          }, 2000);
        } else {
          this.$message({
            showClose: true,
            message: '操作失败！' + res.msg,
            type: 'error'
          });
        }
      }
    },
     // 修改状态
     async updateStatus(val) {
      // let info = {id: this.$route.query.id, status: val}
      this.article.text = this.editor.getText();
      this.getSelectList(this.typeList)
      this.article.userId = (JSON.parse(sessionStorage.getItem('user'))).id
      // console.log(this.selectList)
      this.article.tag = this.tagValueList.join(',')
      this.article.categorysId = [...new Set(this.selectList)];
      this.article.content = this.html;
      let info = { ...this.article };
      delete info.tagsId;
      info.status = val;
      const res = await updateArticleAPI(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '操作成功！',
          type: 'success'
        });
        setTimeout(() => {
          this.html = ''
          this.tagValueList = [];
          this.selectList = [];
          this.defaultTypeList = [];
          this.article.categorysId = [];
          this.article = {
            userId: null, // 用户id
            title: null,  // 标题
            subTitle: null, // 文章副标题
            author: null, //作者
            commentCount: null, //评论总数
            commentStatus: true, //评论状态，默认允许评论
            commentTime: null, //最后评论时间
            content: null, //内容
            created: null, // 创建时间
            editMode: null, // 编辑模式，默认为html，其他可选项包括html，markdown 
            flag: null, // 标识，通常用于对某几篇文章进行标识，从而实现单独查询
            // id: null, // 主键ID
            linkTo: null, // 连接到(常用于谋文章只是一个连接)
            // list: [], // 文章集合
            metaDescription: null, //SEO描述信息
            metaKeywords: null, //SEO关键字
            metaTitle: null, // SEO标题
            modified: null, // 最后更新时间
            options: null, //json扩展
            orderNumber: null, // 排序编号
            pid: null, // 子版本的文章id
            siteId: null, // 站点ID
            slug: null, // slug  固定链接
            tag: null, // 标签
            status: 'normal', // 状态
            style: null, // 样式
            summary: null, // 摘要
            thumbnail: null, // 缩略图
            userId: null,  // 用户id
            viewCount: null, // 访问量
            withAllowSearch: false, // 是否允许被搜索
            withHot: false, // 是否热门
            withLeadNews: false, // 是否头条
            withRecommend: false, // 是否推荐
            withTop: false //  是否置顶
          }
          this.getTypeList();
          this.isEdit = false;
        }, 2000);
      } else {
        this.$message({
          showClose: true,
          message: '操作失败！' + res.msg,
          type: 'error'
        });
      }
      // console.log(val);
      // let info = {}
    },
    // 获取文章分类列表
    async getTypeList() {
      const res = await articleTypeListAPI({ pageNum: 1, pageSize: 10000 });
      // console.log(res);
      if (res.code === 200) {

        // 给分类数组添加是否选中状态，不能直接赋值，否则会导致选择框不能选中的问题
        let arr = res.data.list;
        this.addSelect(arr);
        let list = [];
        arr.forEach(ele => {
          list.push(ele)
        })
        this.typeList = list;
        this.defaultTypeList.forEach(item => {
          this.typeList.forEach(ele => {
            if (item === ele.id) {
              ele.select = true;
            }
            if (ele.children) {
              this.defaultTypeSelect(item, ele.children)
            }
          })
        })
      }
    },
    // 获取文章标签列表
    async getTagsList() {
      const res = await articleTagsListAPI({ pageNum: 1, pageSize: 100000 });
      console.log(res);
      if (res.code === 200) {
        this.tagList = res.data.list;

      }
    },
    // 递归增加是否选中的状态
    addSelect(val) {
      if (val.length > 0) {
        val.forEach(item => {
          item.select = false;
          if (item.children) {
            this.addSelect(item.children)
          }
        })
      }
    },
    // 获取文章详情
    async getDetail() {
      const res = await articleDetailAPI({ id: this.$route.query.id });
      console.log(res);
      if (res.code === 200) {
        this.article = res.data;
        this.html = res.data.content;
        let tagsList = res.data.tagsId;
        let tagsList1 = [];
        tagsList.forEach(item => {
          this.tagList.forEach(ele => {
            if (ele.id === item) {
              tagsList1.push(ele.title)
            }
          })
        })
        // console.log(tagsList1);
        this.tagValueList = tagsList1
        // 之前选择的分类
        this.defaultTypeList = res.data.categorysId;
        
      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '详情获取失败!'
        });
      }
      // console.log(this.typeList)
    },
    // 默认分类选中
    defaultTypeSelect(val, val2) {
      val2.forEach(item => {
        if (val === item.id) {
          item.select = true;
        }
        if(item.children) {
          this.defaultTypeSelect(val, item.children)
        }
      })
    },
    // 获取设置详情
    async getSettingsDetail() {
      const res = await basicSettingsAPI();
      console.log(res);
      if (res.code === 200) {
        this.settingsInfo = res.data;
      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '详情获取失败!'
        });
      }
    }
  },

  mounted() {
    // console.log(this.editorConfig)

    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = ''
    // }, 1500)

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    if (this.$route.query.id) {
      this.isEdit = true;
      this.getDetail();
    }
    let requestAPI = this.$store.state.requestAPI;
    // console.log(this.editorConfig)
    setTimeout(() => {
      this.getTypeList();
    }, 500);
    this.getTagsList();
    this.uploadFile();
    this.getSettingsDetail();
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: `${this.$store.state.requestAPI}/attachment/upload?ct=${this.APIinfo.ct}&sign=${this.APIinfo.sign}&jpressAppId=${this.APIinfo.jpressAppId}`,
      meta: {
        id: 0
      },
      fieldName: 'file',
      // 单个文件上传失败
      onFailed(file, res) {   // TS 语法
        // onFailed(file, res) {           // JS 语法
        console.log(`${file.name} 上传失败`, res)
      },
      // 自定义插入图片
      customInsert(res, insertFn) {
        // console.log(res)
        // console.log(insertFn)
        // customInsert(res, insertFn) {                  // JS 语法
        // res 即服务端的返回结果

        // 从 res 中找到 url alt href ，然后插入图片
        insertFn(requestAPI + res.data.path, res.data.title, res.data.path)
      },

    }
    


  }
}
</script>
<!-- <style src="@wangeditor/editor/dist/css/style.css"></style> -->
<style lang="scss" scoped>
.writeArticle {
  height: 90%;
  .writeTop {
    display: flex;

    .left {
      width: 60%;

      .el-input {
        margin-bottom: 10px;
      }
    }

    .right {
      width: 50%;
      text-align: right;
    }
  }

  .writeContent {
    display: flex;
    width: 100%;
    height: 100%;

    .right {
      padding-left: 10px;
      border-left: 1px solid var(--border-color);
      width: 30%;
      height: 100%;
      overflow-y: scroll;
      .el-select {
        width: 100%;
      }

      .statusCollapse {
        .item {
          margin-bottom: 20px;

          span {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }

  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  ::v-deep .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  ::v-deep .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  ::v-deep .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>